import { useContext } from "react";
import { HomeContext } from "../../../Context/HomeContext";
import box1 from "../../../../../assets/Boxes/ACRILICO/ECUADORIAN_CARTOON.png";
import box2 from "../../../../../assets/Boxes/ACRILICO/heart_H0_cartoon.png";
import box3 from "../../../../../assets/Boxes/ACRILICO/heart_H1_CARTOON.png";
import box4 from "../../../../../assets/Boxes/ACRILICO/heart_H2_CARTOON.png";
import box5 from "../../../../../assets/Boxes/ACRILICO/round_R1T_cartoon.png";
import box6 from "../../../../../assets/Boxes/ACRILICO/round_R2_cartoon.png";
import box7 from "../../../../../assets/Boxes/ACRILICO/round_R3_cartoon.png";
import box8 from "../../../../../assets/Boxes/ACRILICO/round_R4_cartoon.png";
import box9 from "../../../../../assets/Boxes/ACRILICO/square_S1T_CARTOON.png";
import box10 from "../../../../../assets/Boxes/ACRILICO/square_S2_cartoon.png";
import box11 from "../../../../../assets/Boxes/ACRILICO/square_S3_cartoon.png";
import box12 from "../../../../../assets/Boxes/ACRILICO/square_S4_cartoon.png";
import box13 from "../../../../../assets/Boxes/ACRILICO/square_S5_cartoon.png";
import box14 from "../../../../../assets/Boxes/ACRILICO/square_v18_cartoon.png";
import box15 from "../../../../../assets/Boxes/ACRILICO/square_v1cartoon.png";
import box16 from "../../../../../assets/Boxes/ACRILICO/square_v25_cartoon.png";
import box17 from "../../../../../assets/Boxes/ACRILICO/square_v4_cartoon.png";
import box18 from "../../../../../assets/Boxes/ACRILICO/square_v9_cartoon.png";
import box19 from "../../../../../assets/Boxes/PAPEL/heart_l_CARTOON.png";
import box20 from "../../../../../assets/Boxes/PAPEL/heart_m_CARTOON.png";
import box21 from "../../../../../assets/Boxes/PAPEL/heart_ml_CARTOON.png";
import box22 from "../../../../../assets/Boxes/PAPEL/heart_s_CARTOON.png";
import box23 from "../../../../../assets/Boxes/PAPEL/round_l_CARTOON.png";
import box24 from "../../../../../assets/Boxes/PAPEL/round_m1_CARTOON.png";
import box25 from "../../../../../assets/Boxes/PAPEL/round_s_cartoon.png";
import box26 from "../../../../../assets/Boxes/PAPEL/round_xs_CARTOON.png";
import box27 from "../../../../../assets/Boxes/PAPEL/round_xxs_CARTOON.png";
import box28 from "../../../../../assets/Boxes/PAPEL/speck_black_CARTOON.png";
import box29 from "../../../../../assets/Boxes/PAPEL/speck_blue_CARTOON.png";
import box30 from "../../../../../assets/Boxes/PAPEL/speck_pink_CARTOON.png";
import box31 from "../../../../../assets/Boxes/PAPEL/square_l_CARTOON.png";
import box32 from "../../../../../assets/Boxes/PAPEL/square_m1_CARTON.png";
import box33 from "../../../../../assets/Boxes/PAPEL/square_m_CARTOON.png";
import box34 from "../../../../../assets/Boxes/PAPEL/square_ml_CARTOON.png";
import box35 from "../../../../../assets/Boxes/PAPEL/square_s_CARTOON.png";
import box36 from "../../../../../assets/Boxes/PAPEL/square_xs_CARTOON.png";
import box37 from "../../../../../assets/Boxes/PAPEL/square_xxs_CARTOON.png";

export default function Boxes() {
  const { material, boxSelected, setBoxSelected } = useContext(HomeContext);

  const ACRBoxes = [
    box1,
    box2,
    box3,
    box4,
    box5,
    box6,
    box7,
    box8,
    box9,
    box10,
    box11,
    box12,
    box13,
    box14,
    box15,
    box16,
    box17,
    box18,
  ];

  const PAPBoxes = [
    box19,
    box20,
    box21,
    box22,
    box23,
    box24,
    box25,
    box26,
    box27,
    box28,
    box29,
    box30,
    box31,
    box32,
    box33,
    box34,
    box35,
    box36,
    box37,
  ];
  
  

  return (
    <>
      <div className="Rose-Boxes">
        <h5 className="Rose-Boxes-title">Boxes</h5>
        <div className="Rose-Boxes-list">
          {material === "acrylic"
            ? ACRBoxes.map((b, index) => {
                return (
                  <section
                    className="Rose-Boxes-list-item"
                    key={index}
                    onClick={() => {
                      setBoxSelected(b);
                    }}
                    style={{
                      border:
                      boxSelected === b
                          ? "2px solid #000000"
                          : "1px solid #393C41",
                    }}
                  >
                    <img src={b} alt={b} />
                  </section>
                );
              })
            : PAPBoxes.map((t, index) => {
                return (
                  <section
                    className="Rose-Boxes-list-item"
                    key={index}
                    onClick={() => {
                      setBoxSelected(t);
                    }}
                    style={{
                      border:
                      boxSelected === t
                          ? "2px solid #000000"
                          : "1px solid #393C41",
                    }}
                  >
                    <img src={t} alt={t} />
                  </section>
                );
              })}
        </div>
      </div>
    </>
  );
}
