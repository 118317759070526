import CanvasPart from "./Components/CanvasPart/CanvasPart";
import DesignPanel from "./Components/DesignPanel/DesignPanel";
import DesignPanelToggler from "./Components/DesignPanelToggler/DesignPanelToggler";
import HomeContextProvider from "./Context/HomeContext";

export default function HomePage() {
  return (
    <>
      <main className="Home">
        <div className="Home-container ">
          <HomeContextProvider>
            <section className="ShowPart ">
              <CanvasPart />
            </section>

            <section className="DesignPanel row">
              <DesignPanel />
              <section className="DesignPanelToggler col-lg-3 p-0">
                <DesignPanelToggler />
              </section>
            </section>
          </HomeContextProvider>
        </div>
      </main>
    </>
  );
}
